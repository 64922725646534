
import GoogleMapsWrapper from "@/components/map/GoogleMapsWrapper"
import { mapStores } from "pinia"
import { useCameraStore } from "@/stores/camera"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
} from "@evercam/shared/types/analytics"
import CameraMapInfoWindow from "@/components/map/CameraMapInfoWindow"

export default {
  name: "GoogleMap",
  meta: {
    pageId: AnalyticsEventPageId.AccountMapView,
  },
  components: {
    CameraMapInfoWindow,
    GoogleMapsWrapper,
  },
  data() {
    return {
      center: {
        lat: 52.51195,
        lng: 6.089625,
      },
    }
  },
  head() {
    return {
      title: "Evercam | Map View",
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useCameraStore),
    cameraMarkers() {
      return this.cameraStore.cameras.map((camera) => {
        return {
          position: camera.location,
          image: this.getMarkerImage(camera),
          ...camera,
        }
      })
    },
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
  },
  methods: {
    handleInfoWindowVisibilityChange(visible) {
      this.$analytics.saveEvent(
        AnalyticsEvent.MapViewToggleCameraMapInfoTooltip,
        {
          visible,
        }
      )
    },
    getMarkerImage(marker) {
      if (marker.isOnline) {
        return "/online_marker.png"
      } else if (marker.status === "waiting") {
        return "/waiting_for_activation.png"
      } else {
        return "/offline_marker.png"
      }
    },
    onLinkClick() {
      this.$analytics.saveEvent(AnalyticsEvent.MapViewClickCameraLink)
    },
  },
}
